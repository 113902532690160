import React, { useState } from "react"
import styled from "styled-components"
import { GoogleMap, Marker, LoadScript } from '@react-google-maps/api'
import { FontAwesomeSvgIcon } from 'react-fontawesome-svg-icon';
import { faMapMarker, faEnvelope, faPhone, faPrint, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

import { Link } from "gatsby";

import SupportPage from "../components/layout/SupportPage"
import PageHeader from "../components/layout/PageHeader"
import Button from "../components/buttons/Button"
import Card from "../components/layout/Card"
import TextField from "../components/fields/TextField"
import MultiTextField from "../components/fields/MultiTextField"
import PhoneField from "../components/fields/PhoneField"
import EmailField from "../components/fields/EmailField"
import StateSelectField from "../components/fields/StateSelectField"
import SuccessBox from "../components/boxes/SuccessBox"
import Layout2Columns from "../components/layout/Layout2Columns"

import { colors, google, phones } from "../settings"
import { removeNulls } from "../utilities/utils"
import { postData } from "../services/server"
import { isValidPhoneNumber } from "../utilities/validation";

const ContactMethod = styled.div`
	background-color: white;
	border-radius: 3px;
	padding: 15px;
	display: flex;
	align-items: center;
	margin-bottom: 2px;
	 
	svg { 
		margin-right: 10px;
		flex: 0;
		color: ${colors.orange};
	}

	> div {
		flex: 1;
	}
`
const Warning = styled.div`
	margin: 25px 0px;

	a {
		font-weight: bold;
		color: ${colors.red};
	}
`

const ContactPage = () => {

    const [error, setError] = useState(null);
    const [busy, setBusy] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [data, setData] = useState({
		name: "",
		email: "",
		phoneno: "",
		subject: "",
		message: "",
		state: ""
    })
    const [errors, setErrors] = useState({
	})

    function onFieldChange(e) {
		setError(null)
        setData({...data, [e.target.name]: e.target.value});
    }

    function validateForm() {
        const nameValid = data.name && data.name.length > 0;
        const emailValid = data.email && data.email.length > 0;
        const subjectValid = data.subject && data.subject.length > 0;
        const messageValid = data.message && data.message.length > 0;
		const phoneValid = data.phoneno ? (data.phoneno.length == 0 || isValidPhoneNumber(data.phoneno)) : true;
        const stateValid = data.state && data.state.length > 0;

        setErrors({
			name: !nameValid,
			email: !emailValid,
			subject: !subjectValid,
			message: !messageValid,
			phoneno: !phoneValid,
			state: !stateValid
        });

        return nameValid && emailValid && subjectValid && messageValid && stateValid;
	}
	
    function onSubmit() {
        const valid = validateForm();

        if (valid) {
			setError("");
			setBusy(true);

			postData("/api/contact/submit", removeNulls(data),
				(response) => {
					setSubmitted(true);
				}, 
				(error, status) => {
					setError("An error occured sending your message.  Please give us a call at " + phones.sales + ".")
				},
				() => {
					setBusy(false);
				}
			);
		}
		else {
			setError("Please correct the highlighted problems.")
		}
	}

	return (
	<SupportPage page="contact" title="Contact Us | Five Star Customer Support">
		<Layout2Columns align="top">
			<div>
				<ContactMethod>
					<FontAwesomeSvgIcon icon={faMapMarker} size="2x" fixedWidth={true} />
					<div>
						<div>2400 5th Street Road</div>
						<div>Huntington, WV 25701, United States</div>
					</div>
				</ContactMethod>
				<ContactMethod>
					<FontAwesomeSvgIcon icon={faPhone} size="2x" fixedWidth={true} />
					<div>Phone: (800) 863-0107</div>
				</ContactMethod>
				<ContactMethod>
					<FontAwesomeSvgIcon icon={faPhone} size="2x" fixedWidth={true} />
					<div>Phone: (304) 399-3503</div>
				</ContactMethod>
				<ContactMethod>
					<FontAwesomeSvgIcon icon={faPrint} size="2x" fixedWidth={true} />
					<div>Fax: (304) 522-7505</div>
				</ContactMethod>
			</div>
			<div>
				<LoadScript
					id="script-loader"
					googleMapsApiKey={google.googleMapsKey}
				>
					<GoogleMap
						id='example-map'
						mapContainerStyle={{
							height: "300px",
							width: "100%"
						}}
						zoom={6}
						center={{ 
							lat: 38.381521, 
							lng: -82.459042 
						}}
					>
						<Marker position={{ lat: 38.381521, lng: -82.459042 }} title="Sign Monkey" />
					</GoogleMap>
				</LoadScript> 
			</div>
		</Layout2Columns>

		<br />
		<br />

		<PageHeader title="Send Us An Email" />

		{ submitted ? 
			<SuccessBox>
				<p>
					We've received your message.  We'll be in touch soon.
				</p>
			</SuccessBox>
		:
			<>
			<Warning>
				If you are wanting a <strong>sign price</strong> then please fill out 
				our <Link to="/builder/?from=ContactUs">Sign Price</Link> form instead.
			</Warning>
			
			<Card>
				<TextField 
					type="name"
					value={data.name}
					wide
					error={errors.name === true}
					required
					id="name"
					name="name"
					label="Your Name"
					onChange={onFieldChange} 
				/>
				<EmailField 
					type="email"
					value={data.email}
					wide
					error={errors.email === true}
					required
					id="email"
					name="email"
					label="Your Email Address"
					onChange={onFieldChange} 
				/>
				<PhoneField 
					value={data.phoneno}
					wide
					error={errors.phoneno === true}
					id="phoneno"
					name="phoneno"
					label="Your Phone Number"
					onChange={onFieldChange} 
				/>
				<StateSelectField 
					value={data.state}
					wide
					required
					error={errors.state === true}
					id="state"
					name="state"
					label="Your State"
					onChange={onFieldChange} 
				/>				
				<TextField 
					value={data.subject}
					wide
					error={errors.subject === true}
					required
					id="subject"
					name="subject"
					label="Subject"
					onChange={onFieldChange} 
				/>
				<MultiTextField 
					value={data.message}
					wide
					error={errors.message === true}
					id="message"
					name="message"
					label="Your Comments / Questions / Feeback"
					required
					rows={5}
					onChange={onFieldChange} 
				/>

				<br />
				
				<div className="buttons">
					{error && 
						<p style={{color:"red"}}>
							{error}
						</p>
					}
					<Button 
						label="Submit Message"
						icon={faEnvelope}
						busy={busy}
						color="blue"
						size="large"
						onClick={onSubmit}
					/>
				</div>
			</Card>
			</>
		}
	</SupportPage>
	)
}

export default ContactPage